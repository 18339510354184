<template>
  <div id="app" class="url" style="padding: 15px 0">
    <div>
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px">
          <el-page-header @back="goBack" content="组织管理"> </el-page-header>
        </el-col>
      </el-row>
    </div>
    <div style="margin:10px">
        <el-button
          size="medium"
          type="success"
          @click="addCompany"
          >添加组织</el-button
        >
    </div>
    <div>
      <el-table
        border
        :data="
          companyData.filter(
            (data) =>
              !search ||
              data.name.toLowerCase().includes(search.toLowerCase())
          ) "
          max-height="800px"
      >
        <el-table-column prop="name" label="组织名称" width="auto">
        </el-table-column>
        <el-table-column prop="accountName" label="主账户" width="150px">
        </el-table-column>
        <el-table-column prop="parentName" label="所属组织" width="150px">
        </el-table-column>
        <el-table-column align="center" width="auto">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="search"
              size="medium"
              placeholder="输入组织名称搜索"
            />
          </template>
          <template slot-scope="scope">
            <el-button
              size="medium"
              type="primary"
              @click="addCompany(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="medium"
              type="danger"
              @click="delCompany(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    
    <div>
      <el-dialog title="添加组织" 
        @close="closeDialog('formData')"
       :visible.sync="dialogStatus"
        width="50%">
        <el-form ref="formData" :model="formData" label-width="100px" :rules="companyRules">
          <el-row :gutter="12">
            <el-col :span="8">
              <el-form-item label="组织名称" prop="name">
                <el-input v-model="formData.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" :push="6">
              <el-form-item label="主账户"  prop="account" >
                <el-input v-model="formData.account" :disabled="isEdit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="12" :hidden="isEdit">
            <el-col :span="8">
              <el-form-item label="登录密码" prop="password">
                <el-input v-model="formData.password"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" :push="6">
              <el-form-item label="确认密码" prop="encryptPwd">
                <el-input v-model="formData.encryptPwd"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="12">
            <el-col :span="8">
              <el-form-item label="上级组织" prop="parentId">
                <el-select v-model="formData.parentId" :disabled="isEdit">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" :push="6" :hidden="isEdit">
              <el-form-item label="主账户类型" prop="dispatcher">
                 <el-select v-model="formData.dispatcher">
                  <el-option
                    v-for="item in useroptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="12">
            <el-col :span="6" >
              <el-form-item>
                <el-button type="primary" @click="submitForm('formData')">保存</el-button>
                <el-button @click="closeDialog('formData')">关闭</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
    </div>
    
  </div>
</template>

<script>
import dataStorage from '@/../utils/dataStorage.js'
import BaseData from '@/assets/config/BaseData.js';
import axios from "axios";

export default {
  data() {
    return {
      isEdit:false,
      row:true,
      dialogStatus:false,
      formData:{
        jsession:dataStorage.getBaseData("videoJsession"),

        name:"",   // 组织名称
        account:"",// 主账户

        parentId:"",// 上级组织id
        password:"000000", // 密码
        encryptPwd:"000000",
        id:"", // 编辑用的 传id
        dispatcher:"", // 主账户类型
      },
      companyRules:{
        name: [
          { required: true, message: '请选择填写组织名称', trigger: 'change' }
        ],
        account: [
          { required: true, message: '请选择填写主账户', trigger: 'change' }
        ]
      },
      options: [],
      useroptions:[
        {
          name:"普通用户",
          id:0
        },{
          name:"调度用户",
          id:1
        },
      ],

      companyData: [],
      search: "",
    };
  },
  created() {
    this.findCompany()
  },

  methods: {
    submitForm(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          // 新增组织接口 有id 为修改
          axios
          .get( "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_mergeCompany.action", {
            params:this.formData,
          })
          .then((res) => {
            this.dialogStatus = false
            this.$refs[formData].resetFields()

            this.findCompany()
            if (res.data.result == 0) {
              this.$message.success("添加成功")
            }else{
              this.$message.error("添加失败")
            }
          });
        }
      });
    },
    closeDialog(formData){
      this.dialogStatus = false
      this.$refs[formData].resetFields();
    },
    delCompany(row){
      console.log(row);
      this.$confirm('此操作将永久删除该组织, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios
        .get( "http://39.97.231.64:8088/StandardApiAction_deleteCompany.action", {
          params: {
            jsession: dataStorage.getBaseData("videoJsession"),
            id:row.id,
            name:row.name
          },
        })
        .then((res) => {
           console.log(res);
           this.$message.success("删除成功")
        });
      }).catch(() => {
          this.$message.info("取消删除")
      });
    },
    addCompany(row){
      this.dialogStatus=true
      if (row.id) {
        this.isEdit=true
        // console.log(row);
        // this.formData = row
        this.formData.id = row.id
        this.formData.name = row.name
        this.formData.account = row.accountName
        // accountName
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].id == row.parentId) {
            this.formData.parentId = this.options[i].id
          }
        }
      }else{
        this.isEdit=false
      }
    },
    findCompany() {
      axios
        .get( "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_findCompany.action", {
          params: {
            jsession: dataStorage.getBaseData("videoJsession"),
          },
        })
        .then((res) => {
          console.log(res);
           this.companyData = res.data.infos

           this.options  = JSON.parse(JSON.stringify(res.data.infos));
           this.options.unshift({
            id:0,
            name:"无"
           })
        });
    },
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },
  },
};
</script>

<style lang="scss" scope>
</style>
